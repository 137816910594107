export enum PageType {
  PRIVACY_POLICY = 'privacyPolicy',
  TERMS_CONDITIONS = 'termsAndConditions',
  ABOUT_US = 'aboutUs',
  FAQ = 'faq',
  SUPPORT_AND_CONTACT = 'supportAndContact',
  DELETE_ACCOUNT = 'deleteAccount',
  TERMS_CONDITIONS2 = 'terms_conditions',
  PRIVACY_POLICY2 = 'privacy_policy',
  DELETE_ACCOUNT2 = 'delete_account',
}

export type PageTypes = 'privacyPolicy' | 'termsAndConditions' | 'aboutUs' | 'faq' | 'supportAndContact';
